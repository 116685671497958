import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography, IconButton, InputAdornment, Link } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Auth } from "aws-amplify";

const SignIn = ({ setJwtToken }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [signInState, setSignInState] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({ username: "", password: "" });

  const handleSignInChange = (field, e) => {
    setSignInState({ ...signInState, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const validateForm = () => {
    const newErrors = { username: "", password: "" };
    let valid = true;

    if (!signInState.username) {
      newErrors.username = "*Mandatory";
      valid = false;
    }
    if (!signInState.password) {
      newErrors.password = "*Mandatory";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      const user = await Auth.signIn(
        signInState.username,
        signInState.password
      );
      const token = user.signInUserSession.idToken.jwtToken;

      sessionStorage.setItem("MFE_Token", token);
      setJwtToken(token);
      sessionStorage.setItem("userName", signInState.username);

      navigate("/home");
    } catch (error) {
      console.log("Error signing in", error);
      setErrors({ ...errors, password: "Username or Password is Wrong" });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10vh",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "450px" },
          border: "1px solid #ccc",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Georgia, serif",
            fontSize: "16px",
            color: "#333",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              marginBottom: 3,
              fontFamily: "Georgia, serif",
              color: "#3d3d3d",
            }}
          >
            Welcome Back
          </Typography>

          <Box component="form">
            <Typography
              variant="h6"
              sx={{
                color: "#444",
                fontFamily: "Georgia, serif",
                marginBottom: 1,
              }}
            >
              Username
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your username"
              onChange={(e) => handleSignInChange("username", e)}
              value={signInState.username}
              sx={{
                marginBottom: 3,
                borderColor: "#ccc",
                fontFamily: "Arial",
                fontSize: "16px",
              }}
              error={Boolean(errors.username)}
              helperText={errors.username}
              FormHelperTextProps={{
                sx: { color: "red", fontFamily: "Arial" },
              }}
            />
            <Typography
              variant="h6"
              sx={{
                color: "#444",
                fontFamily: "Georgia, serif",
                marginBottom: 1,
              }}
            >
              Password
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter your password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={signInState.password}
              onChange={(e) => handleSignInChange("password", e)}
              sx={{
                marginBottom: 3,
                borderColor: "#ccc",
                fontFamily: "Arial",
                fontSize: "16px",
              }}
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        height: 55,
                        width: 2,
                        backgroundColor: "rgba(0, 0, 0, 0.23)",
                        marginRight: 1,
                      }}
                    />
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
                textTransform: "none",
                backgroundColor: "#007887",
                color: "#fff",
                fontFamily: "Georgia, serif",
                fontWeight: "bold",
                padding: 1.5,
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#006f7b",
                },
              }}
              onClick={handleLogin}
            >
              Sign in
            </Button>
            <Typography
              variant="body2"
              sx={{
                mt: 3,
                textAlign: "center",
                fontFamily: "Arial",
                color: "#777",
              }}
            >
              <Link href="#" underline="hover" sx={{ color: "#007887" }}>
                Forgot your password?
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
