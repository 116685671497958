import React, { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import ProductList from "./Components/ProductList";
import ProductForm from "./Components/ProductForm";
import SignIn from "./Components/Login/SignIn";
import PrivateRoute from "./Components/Login/PrivateRoute";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

Amplify.configure(awsExports);

const App = () => {
  const [products, setProducts] = useState([]);
  const [jwtToken, setJwtToken] = useState(null);
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("MFE_Token");
    if (token) {
      setJwtToken(token);
    }
  }, []);

  useEffect(() => {
    if (jwtToken) {
      fetch("https://e-comshop.shop/api/products", {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => setProducts(data));
    }
  }, [jwtToken]);

  const handleAddProduct = (product) => {
    fetch("https://e-comshop.shop/api/products", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(product),
    })
      .then((response) => response.json())
      .then((newProduct) => setProducts([...products, newProduct]));
  };

  const handleRemoveProduct = (id) => {
    fetch(`https://e-comshop.shop/api/products/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then(() =>
      setProducts(products.filter((product) => product._id !== id))
    );
  };

  const handleEdit = (editedProduct) => {
    fetch(`https://e-comshop.shop/api/products/${editedProduct._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(editedProduct),
    })
      .then((response) => response.json())
      .then((updatedProduct) => {
        setProducts(
          products.map((p) =>
            p._id === updatedProduct._id ? updatedProduct : p
          )
        );
      });
  };

  const handleLogout = () => {
    sessionStorage.removeItem("MFE_Token");
    sessionStorage.removeItem("userName");
    setJwtToken(null);
    setRedirectToSignIn(true);
    window.location.reload();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Router basename="/">
      <div className="container mx-auto p-4">
        <div className="flex items-center justify-center mb-6">
          <h1 className="text-4xl text-gray-800 leading-tight tracking-wider shadow-lg text-center">
            Malli's House Holds
          </h1>
        </div>
        {redirectToSignIn ? (
          <Navigate to="/signin" replace />
        ) : (
          <Routes>
            {!jwtToken && (
              <Route
                path="/signin"
                element={<SignIn setJwtToken={setJwtToken} />}
              />
            )}
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <div>
                    {/* <a
                      href="/https://e-comshop.shop/#/signin.zip"
                      download
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Download App
                    </a> */}
                    <div className="flex items-center justify-end mb-4">
                      <h3 className="text-2xl text-gray-800 leading-tight text-center">
                        {sessionStorage.getItem("userName")}
                      </h3>
                      <IconButton
                        onClick={handleMenuOpen}
                        style={{ backgroundColor: "" }}
                      >
                        <Avatar
                          src="/path-to-avatar.jpg"
                          style={{ backgroundColor: "#3B82F6" }}
                        />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </Menu>
                    </div>
                    <ProductForm onAdd={handleAddProduct} />
                    <h4 className="text-1xl font-bold mb-4">
                      Product Management
                    </h4>
                    <ProductList
                      products={products}
                      onRemove={handleRemoveProduct}
                      onEdit={handleEdit}
                    />
                  </div>
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default App;
