import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!sessionStorage.getItem("MFE_Token");

  return isAuthenticated ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
